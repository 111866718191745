// src/plugins/MyUploadAdapter.js

const url = process.env.VUE_APP_API_URL;
const TOKEN_KEY = 'access_token';

export default class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    const token = localStorage.getItem(TOKEN_KEY);
    return this.loader.file.then(
      file => new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(`${url}/v1/uploads`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        })
          .then(response => response.json())
          .then((data) => {
            if (data.data) {
              resolve({
                default: data.data, // URL of the uploaded image
              });
            } else {
              reject(data.error || 'Upload failed');
            }
          })
          .catch((error) => {
            reject(error);
          });
      }),
    );
  }
}

// Plugin function to create and register the custom upload adapter plugin
export function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => new MyUploadAdapter(loader);
}
